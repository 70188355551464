<template>
    <div class="relative chart-wrap">
        <highcharts
            :class="pieOptions.classname"
            :options="options"
            ref="PieChart"
            :id="pieOptions.id">
        </highcharts>     
        <div
            class="absolute top-0 left-0 h-full w-full flex flex-col items-center justify-center text-xs"
        >
            <slot />
        </div>
    </div>
</template>

<script>

export default {
    name: 'PieChart',
    props: ['data','pieOptions'],
    data() {
        return {
            options: {
                chart: {
                    type: 'pie',
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                    borderWidth:0,
                    backgroundColor: 'rgba(255,255,255,0)',
                    margin: [0,0,0,0],
                    spacingTop:0,
                    spacingBottom:0,
                    spacingRight:0,
                    spacingLeft:0,
                },
                title: {
                    text: '',
                    align: 'center',
                    verticalAlign: 'middle',
                    y: 40
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    enabled: true,
                    style: {
                        color: "#fff",
                    },
                    borderRadius: 20,
                    headerFormat: "",
                    // shared: true,
                    useHTML: true,
                    outside: true,
                    // footerFormat: '',
                    // xDateFormat: '%b %Y',
                    pointFormat: '<span class="capitalize" style="color:{point.color}">{point.name}</span>: <b>{point.value:,.0f}</b>',
                },
                colors: this.pieOptions.colors,
                plotOptions: {
                    pie: {
                        slicedOffset: 5,
                        size:'90%',
                        dataLabels: {
                            enabled: false,
                        },
                        startAngle: 0,
                        endAngle: 360,
                        center: ['50%', '50%'],
                        borderWidth: 3,
                        borderColor: this.pieOptions.bgColor,
                    },
                    series: {
                        animation: {
                            duration: 1500
                        },
                        linecap: 'round',
                        sliced: true,
                        innerSize: this.pieOptions.innerSize + '%',
                        states: {
                            hover: {
                                halo: {
                                    size: 9,
                                    attributes: {
                                        fill: this.pieOptions.colors,
                                        'stroke-width': 2,
                                        stroke: this.pieOptions.colors
                                    }
                                }
                            }
                        }
                    }
                },
                // series: [
                //     {
                //         type: 'pie',
                //         name: this.names,
                //         innerSize: this.pieOptions.innerSize + '%',
                //         data: this.data,
                //         sliced: true
                //     }
                // ]
                series: {
                    name: 'PieChart',                   
                    data: []
                }
            }
        }
    },

    methods: {
        updateChart() {
            var chart = this.$refs["PieChart"].chart;
            this.options.series.data = this.data;
            chart.redraw();
        },

    },
    watch: {
       data() {
            this.updateChart();
        },

    },
    mounted() {
        this.updateChart();
    }
};
</script>
<style>
/* .highcharts-container svg {
    overflow: visible !important;
    z-index: 999;
} */
</style>