<template>
    <div class="flex flex-col flex-1 w-full h-full">
        <ModuleHeader :noFilters="false">
            <template v-slot:module>
                <div class="flex items-center justify-between w-full">
                    Ventas global
                    <a href="#anchor">
                        <Button @buttonAction="buttonAction()" class="m-0">Solo administración</Button>
                    </a>
                </div>
            </template>
            <template v-slot:period>
                {{ period.from | moment('DD MMM YY') }}
                <i class="mdi mdi-arrow-right"></i>
                {{ period.to | moment('DD MMM YY') }}
            </template>
            <template v-slot:market>{{ market }}</template>
        </ModuleHeader>

        <template>
            <div class="flex flex-col extra:flex-row">
                <div class="flex flex-col justify-between w-full mr-1 extra:w-4/12">
                    <!-- VENTAS -->
                    <div class="flex w-full h-80">
                        <Gauge
                            :gaugeOptions="gaugeOptions"
                            :colorSuccessPane="successGradient"
                            :colorWarningPane="warningGradient"
                            :chartId="gaugeOptions.id"
                            :primary="data.current_percent"
                            :primary_name="'Ventas /objetivo'"
                            :secondary="data.previous_percent"
                            :secondary_name="'Ventas /objetivo anterior'"
                            :gradientGauge="true"
                        >
                            <template>
                                <span
                                    class="text-base capitalize text-casika"
                                >{{ selectedMonth.month }} {{ selectedMonth.year }}</span>
                                <div class="xl-value xl:xxl-value tv:text-4xl">
                                    {{ data.current | numFormat('0,0.0a') }}
                                    <small>€</small>
                                </div>
                                <div
                                    class="md-value"
                                    :class="{
                                        'text-good': data.growth_percent && data.growth_percent >= 0,
                                        'text-bad': data.growth_percent && data.growth_percent < 0,
                                    }"
                                >{{ data.growth_percent | numFormat('0.0') }}%</div>
                                <div
                                    :content="'Mes anterior'"
                                    v-tippy="{ followCursor: true, arrow: false }"
                                    class="cursor-pointer sm-value"
                                >{{ data.previous | numFormat('0,0.0a') }} €</div>
                            </template>
                        </Gauge>
                    </div>

                    <Box class="h-auto extra:h-64">
                        <template v-slot:header></template>
                        <template v-slot:content>
                            <div class="flex flex-col items-center justify-center h-full">
                                <div class="flex flex-col items-center my-4">
                                    <span
                                        class="text-sm text-gray1"
                                    >Objetivo {{ selectedMonth.month }} {{ selectedMonth.year }}</span>
                                    <div class="font-medium xl-value">
                                        {{ data.target_month | numFormat('0,0.0a') }}
                                        <small>€</small>
                                    </div>

                                    <Chip
                                        :colorBg="'bg-regular-opacity-33'"
                                        :colorText="'text-yellow-500'"
                                    >Faltan {{ data.target_gap | numFormat('0,0.0a') }}€</Chip>
                                </div>

                                <div class="mt-2 mb-1 separator"></div>

                                <div
                                    class="flex flex-col items-center justify-center w-full md:flex-row"
                                >
                                    <div class="w-full h-40 md:w-2/3 md:h-32 xl:h-32">
                                        <Gauge
                                            :gaugeOptions="{
                                                classname: 'z-30 relative',
                                                innerSize: 80,
                                                bgColor: '#2E384A',
                                                paneColor: '#5D76DD',
                                            }"
                                            :colorSuccessPane="successGradient"
                                            :primary="data.current_day_percent"
                                            :primary_name="'Consecución objetivo diario'"
                                            :chartId="'gauge-daily'"
                                            :gradientGauge="true"
                                        >
                                            <template>
                                                <span class="text-sm text-gray1">Hoy</span>
                                                <!-- <div class="font-medium lg-value">{{ data.current_day | numFormat('0,0.0a') }} <small>€</small></div>    -->
                                                <div class="font-medium lg-value">
                                                    {{ data.current_day | numFormat('0,0.0a') }}
                                                    <small>€</small>
                                                </div>
                                            </template>
                                        </Gauge>
                                    </div>
                                    <div class="flex flex-col items-center w-full my-2 md:w-1/3">
                                        <span class="text-sm text-gray1">Objetivo hoy</span>
                                        <div
                                            class="text-base font-medium text-white md:text-sm xl:text-base"
                                        >
                                            {{ data.target_day | numFormat('0,0.0a') }}
                                            <small>€</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Box>
                </div>

                <div class="flex flex-col justify-end w-full ml-1 extra:w-8/12">
                    <!-- DISTRIBUCIÓN -->
                    <div class="flex flex-col justify-between w-full extra:flex-row">
                        <div v-if="data.countries" class="w-full extra:w-1/2">
                            <div class="mr-1 box">
                                <PieDistribution
                                    :icon="'map-marker-outline'"
                                    :name="'Mercados'"
                                    :data="data.countries"
                                />
                            </div>
                        </div>
                        <div v-if="data.markets" class="w-full extra:w-1/2">
                            <div class="ml-1 box">
                                <PieDistribution
                                    :icon="'shopping'"
                                    :name="'Markets'"
                                    :data="data.markets"
                                />
                            </div>
                        </div>
                    </div>

                    <!-- EVOLUCIÓN -->
                    <div class="flex justify-between w-full">
                        <Box>
                            <template v-slot:header>
                                <div class="flex flex-col items-center justify-between md:flex-row">
                                    Evolución ventas
                                    <div class="flex">
                                        <Legend
                                            v-for="item in evolutive_chart_legends"
                                            :key="item.id"
                                            :colorIcon="item.color"
                                        >
                                            <template v-slot:legend>{{ item.name }}</template>
                                        </Legend>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:content>
                                <div class="w-full h-48">
                                    <AreaSpline
                                        :datatype="'€'"
                                        :chartId="'chartId'"
                                        :data="data.evolution"
                                        :xDate="'%B %Y'"
                                    />
                                </div>
                            </template>
                        </Box>
                    </div>

                    <!-- DÍA + SATISFACCIÓN -->
                    <div class="flex flex-col justify-between w-full extra:flex-row">
                        <div class="flex w-full mr-1 extra:w-3/12">
                            <Box>
                                <template v-slot:header>
                                    <div class="w-11/12 truncate">Mejor día últimos 7</div>
                                </template>
                                <template v-slot:content>
                                    <div class="flex flex-col items-center justify-center mt-5">
                                        <div class="my-1 lg-value">{{ data.best_day }}</div>
                                        <div
                                            class="sm-value"
                                        >{{ data.best_day_value | numFormat('0,0.0a') }}€</div>
                                        <div class="sm-value">{{ data.best_day_units }} unidades</div>
                                    </div>
                                </template>
                            </Box>
                        </div>

                        <div class="flex w-full ml-1 extra:w-9/12">
                            <Box>
                                <template v-slot:header>Satisfacción del cliente</template>
                                <template v-slot:content>
                                    <div
                                        class="flex flex-col items-center justify-between extra:flex-row"
                                    >
                                        <div
                                            class="flex flex-col w-full extra:w-4/12 extra:flex-row"
                                        >
                                            <div class="w-full h-24 extra:w-5/12">
                                                <Gauge
                                                    :gaugeOptions="gaugeOptionsSatisfaction"
                                                    :colorSuccessPane="gaugeOptionsSatisfaction.paneColor"
                                                    :primary="data.client.percent"
                                                    :primary_name="'% de 4 ó 5 estrellas'"
                                                    :chartId="gaugeOptionsSatisfaction.id"
                                                    :gradientGauge="true"
                                                >
                                                    <i class="text-2xl mdi mdi-star text-good"></i>
                                                </Gauge>
                                            </div>
                                            <div
                                                class="flex flex-col items-center justify-center w-full text-center extra:w-7/12"
                                            >
                                                <div
                                                    class="text-xl font-medium text-white xl:text-2xl"
                                                >
                                                    {{ data.client.percent | numFormat('0,0.0a') }}
                                                    <small>%</small>
                                                </div>
                                                <Kpi>
                                                    <template slot="kpi">Últimos 3 meses</template>
                                                    <template slot="value">
                                                        <span
                                                            :class="{
                                                                'text-good':
                                                                    data.client && data.client.growth_percent >= 0,
                                                                'text-bad':
                                                                    data.client && data.client.growth_percent < 0,
                                                            }"
                                                        >
                                                            {{
                                                            data.client.growth_percent | numFormat('0,0.0a')
                                                            }}%
                                                        </span>
                                                    </template>
                                                </Kpi>
                                            </div>
                                        </div>

                                        <div class="block my-2 extra:hidden separator"></div>

                                        <div class="w-full h-24 extra:w-8/12">
                                            <StackedPercent
                                                :chartId="'chartId'"
                                                :data="data.client.evolution"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { state } from '@/store';
    import { SalesGlobal } from '@/api/utils.js';
    import ModuleHeader from '@/components/header/ModuleHeader.vue';
    import Box from '@/components/Box.vue';
    import Chip from '@/components/Chip.vue';
    import Kpi from '@/components/Kpi.vue';
    import Gauge from '@/components/charts/Gauge.vue';
    import AreaSpline from '@/components/charts/AreaSpline.vue';
    import StackedPercent from '@/components/charts/StackedPercent.vue';
    import PieDistribution from '@/components/PieDistribution.vue';
    import Button from '@/components/elements/Button.vue';
    import Legend from '@/components/elements/Legend.vue';
    import numFormat from 'vue-filter-number-format';

    export default {
        name: 'SalesGlobal',
        props: {
            data: {
                type: Object,
                required: false,
                default: () => ({}),
            },
            market: {
                type: String,
                required: false,
                default: 'Mercado',
            },
            period: {
                type: Object,
                required: false,
                default: () => ({}),
            }
        },
        components: {
            ModuleHeader,
            Box,
            Chip,
            Kpi,
            Gauge,
            AreaSpline,
            StackedPercent,
            PieDistribution,
            Button,
            Legend,
        },
        data() {
            return {
                dataFake: {},
                dummyValue: 17789.98,
                gaugeOptions: {
                    id: 'gauge',
                    y: 24,
                    classname: 'z-30 relative',
                    innerSize: 80,
                    bgColor: '#2E384A',
                    paneColor: '#FF5D61',
                },
                gaugeOptionsSatisfaction: {
                    id: 'gauge-satisfaction',
                    y: 24,
                    classname: 'z-30 relative',
                    innerSize: 90,
                    bgColor: '#2E384A',
                    paneColor: '#6ECAA4',
                },
                evolutive_chart_legends: [
                    {
                        id: 0,
                        name: 'Actual',
                        color: '#5D76DD',
                    },
                    {
                        id: 1,
                        name: 'Anterior',
                        color: '#596575',
                    },
                    {
                        id: 2,
                        name: 'Tendencia',
                        color: '#F6B188',
                    },
                ],
            };
        },
        methods: {
            numFormat,
            buttonAction() {
                this.scrollIntoView();

                this.$emit('openModal');
            },
            scrollIntoView() {
                const el = this.$el.querySelector('#returns');
                if (el) {
                    el.scrollIntoView();
                }
            },
        },

        computed: {
            selectedMonth() {
                return state.selectedMonth;
            },
            successGradient() {
                return {
                    linearGradient: [0, 0, 300, 300],
                    stops: [
                        // [0.2, '#0ACFFE'],
                        // [0.55, '#495AFF'],
                        // [0.99, '#13f2e3'],

                        // [0.1, '#FF5F6D'],
                        // [0.99, '#FFC371'],

                        [0.1, '#f29259'],
                        [0.5, '#F6B188'],
                        [0.99, '#f29259'],
                    ],
                };
            },
            warningGradient() {
                return {
                    linearGradient: [0, 0, 300, 300],
                    stops: [
                        [0.2, '#F7CB85'],
                        [0.55, '#F7CB85'],
                        [0.99, '#FE694E'],
                    ],
                };
            },
        },
        created() {
            this.dataFake = SalesGlobal;
        },
    };
</script>
