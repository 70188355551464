<template>
    <div class="w-full flex flex-col md:flex-row">
        <div class="w-full tb:w-6/12 lg:w-5/12 h-32 lg:h-40">

            <Pie
                :data="distribution"
                :pieOptions="pieOptions"
                :chartId="pieOptions.id">
                <i
                    class="mdi text-xl text-gray1"
                    :class="'mdi-' + icon"></i>
                <span class="sm-title">{{name}}</span>
            </Pie>

        </div>
        <div class="w-full tb:w-6/12 lg:w-7/12 flex flex-col justify-center text-left">
            <div
                v-for="(item, index) in data"
                :key="item.id" 
                class="flex justify-around items-center">
                <div
                    class="sm-value text-center w-16"
                    :style="{color: pieOptions.colors[index]}">{{ item.percent | numFormat('0,0.0') }}%</div>
                <div class="sm-title flex-1">{{ item.name }}</div>
            </div>
        </div>

    </div>
</template>

<script>
    import Pie from '@/components/charts/Pie.vue';
    export default {
        name: 'PieDistribution',
        components: {
            Pie,
        },
        props: ['data','icon','name'],
        data() {
            return {                
                pieOptions: {
                    id: 'pie',                    
                    // data: [43,33,10,4],
                    classname: 'z-30 relative overflow-auto',
                    innerSize: 75,
                    bgColor: '#202837',
                    colors: ['#4F65E0','#77AAD6','#A279E2','#E69D54'],
                },
            }
        },
        computed: {
            distribution() {
                let pie = [];
                this.data.map((item) => {
                    // pie.push(item.percent);
                    pie.push({
                        id: item.id,
                        name: item.name,
                        y: item.percent,
                        value: item.value
                    })
                })
                return pie;
                
            },
        }
    }
</script>