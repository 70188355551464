<template>
    <div class="w-full h-full px-3 py-3">
        <Header />

        <div class="module">
            <SalesGlobal />
        </div>

        <div class="module">
            <div class="lg-title">Ventas globales</div>
            <div class="box sm-title">Esto es un box</div>

            <div class="md-title">Esto es un md-title</div>

            <div class="button">Aquí un botón</div>

            <div class="button-tab">
                <div class="tabs flex">
                    <div
                        v-model="activeTab"
                        :value="'tab1'"
                        @click="changeTab"
                        :class="{ active: activeTab == 'tab1' }"
                        class="tab"
                    >
                        Tab 1
                    </div>
                    <div
                        v-model="activeTab"
                        :value="'tab2'"
                        @click="changeTab"
                        :class="{ active: activeTab == 'tab2' }"
                        class="tab"
                    >
                        Tab 2
                    </div>
                </div>
            </div>

            <Chip :colorBg="'bg-pink-400'" :colorText="'text-pink-500'" />
            <Chip :colorBg="'bg-yellow-400'" :colorText="'text-yellow-500'" />
            <Type :colorBg="'bg-yellow-400'" :colorText="'text-gray-800'" />

            <div class="box">
                <div class="sm-title">Esto es un box</div>
                <div class="h-56 w-1/2 flex">
                    <Pie :pieOptions="pieOptions" :chartId="pieOptions.id" />
                    <Gauge :gaugeOptions="gaugeOptions" :chartId="gaugeOptions.id" :gradientGauge="true" />
                </div>
            </div>

            <div class="box">
                <div class="sm-title">Esto es un box</div>
                <div class="w-1/2 h-56">
                    <AreaSpline :chartId="'chartId'" />
                </div>
            </div>

            <div class="box">
                <div class="sm-title">Stacked Percent</div>
                <div class="w-1/2 h-56">
                    <StackedPercent :chartId="'chartId'" />
                </div>
            </div>

            <div class="box">
                <div class="sm-title">Esto es un box</div>
                <div class="w-1/2 h-56">
                    <Stacked :chartId="'chartId'" />
                </div>
            </div>

            <div class="w-1/2 flex flex-col">
                <HorizontalBar v-for="i in 3" :key="'mayo' + i" :colorBg="'orange-gradient'" />
                <HorizontalBar v-for="i in 3" :key="'junio' + i" :colorBg="'blue-gradient'" />
            </div>
        </div>

        <div class="module">
            <div class="lg-title">Top 20</div>
            <div class="flex flex-wrap justify-between -mx-1">
                <ProductCard v-for="i in 20" :key="'julio' + i" />
            </div>
        </div>

        <h3 class="font-medium">Usuario</h3>
        {{ user }}
    </div>
</template>

<script>
    import { state } from '@/store';
    import Header from '@/components/header/Header.vue';
    import Pie from '@/components/charts/Pie.vue';
    import Gauge from '@/components/charts/Gauge.vue';
    import StackedPercent from '@/components/charts/StackedPercent.vue';
    import Stacked from '@/components/charts/Stacked.vue';
    import AreaSpline from '@/components/charts/AreaSpline.vue';
    import HorizontalBar from '@/components/charts/HorizontalBar.vue';
    import ProductCard from '@/components/ProductCard.vue';
    import Chip from '@/components/Chip.vue';
    import Type from '@/components/Type.vue';

    import SalesGlobal from '@/components/module/SalesGlobal.vue';

    export default {
        name: 'Plantilla',
        components: {
            Header,
            Pie,
            Gauge,
            StackedPercent,
            Stacked,
            AreaSpline,
            HorizontalBar,
            ProductCard,
            Chip,
            Type,
            SalesGlobal,
        },
        data() {
            return {
                activeTab: '',
                pieOptions: {
                    id: 'pie',
                    colors: ['#42C6D7', '#D8947B', '#A279E2', '#368AD0', '#F87B7B', '#6ECAA4'],
                    data: [43, 33, 10, 4],
                    classname: 'z-30 relative',
                    innerSize: 80,
                    bgColor: '#202837',
                },
                gaugeOptions: {
                    id: 'gauge',
                    colors: ['#42C6D7', '#D8947B', '#A279E2', '#368AD0', '#F87B7B', '#6ECAA4'],
                    y: 24,
                    classname: 'z-30 relative',
                    innerSize: 75,
                    bgColor: '#2E384A',
                },
            };
        },
        computed: {
            user() {
                return state.user;
            },
        },
        methods: {
            changeTab(tab) {
                this.activeTab = tab;
                //console.log(tab, this.activeTab);
            },
        },
    };
</script>
