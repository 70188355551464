<template>
    <div id="returns" class="w-full flex flex-col h-full flex-1">
        <ModuleHeader>
            <template v-slot:module>
                <div class="w-full flex justify-between items-center">
                    Devoluciones
                    <div class="hidden md:flex w-full md:w-4/12 justify-center md:justify-end">
                        <ButtonTabs :createdTabs="tabs" @selectedTab="selectedTab" />
                    </div>
                </div>
            </template>
            <template v-slot:period>
                {{ period.from | moment('DD MMM YY') }} <i class="mdi mdi-arrow-right"></i>
                {{ period.to | moment('DD MMM YY') }}
            </template>
            <template v-slot:market>
                {{ market }}
            </template>
        </ModuleHeader>

        <template>
            <div class="w-full flex flex-col md:flex-row">
                <Box class="w-full box md:w-5/12 xl:w-4/12 mr-2 flex flex-col flex-1 h-full">
                    <template v-slot:header> Devoluciones </template>
                    <template v-slot:content>
                        <div class="w-full relative flex flex-col extra:flex-row justify-center items-center">
                            <div class="w-full h-48">
                                <Gauge
                                    :gaugeOptions="gaugeOptions"
                                    :colorSuccessPane="gaugeOptions.paneColor"
                                    :chartId="gaugeOptions.id"
                                    :primary="data.percent"
                                    :primary_name="'% devoluciones'"
                                    :gradientGauge="true"
                                >
                                    <template>
                                        <div class="text-white text-2xl xl:text-4xl font-medium pt-1 text-bad">
                                            {{ data.current | numFormat('0') }}
                                        </div>
                                        <span class="sm-title mb-4 -mt-2">Artículos devueltos</span>
                                        <div
                                            class="md-value"
                                            :class="{
                                                'text-good': data.growth_percent <= 0,
                                                'text-bad': data.growth_percent > 0,
                                            }"
                                        >
                                            <span v-if="data.growth_percent > 0">+</span
                                            >{{ data.growth_percent | numFormat('0.0') }}%
                                        </div>
                                    </template>
                                </Gauge>
                            </div>
                        </div>
                    </template>
                </Box>

                <div class="flex md:hidden w-full md:w-4/12 justify-center md:justify-end">
                    <ButtonTabs :createdTabs="tabs" @selectedTab="selectedTab" />
                </div>
                <div class="w-full md:w-7/12 xl:w-8/12 mr-1 flex flex-col h-64 overflow-auto">
                    <ListLarge
                        v-for="(item, index) in data.return_by_product[returns[selectedItemType].type]"
                        :key="item.id"
                    >
                        <template v-slot:photo>
                            <div class="w-12 h-12 rounded-full overflow-hidden my-2 tiny:mb-0">
                                <img
                                    v-if="returns[selectedItemType].type === 'categories' || item.image == null"
                                    class="w-full h-full object-cover"
                                    src="img/default.jpg"
                                    alt=""
                                />
                                <img v-else class="w-full h-full object-cover" :src="item.image" alt="" />
                            </div>
                        </template>
                        <template v-slot:content>
                            <div
                                :content="item.name"
                                v-tippy="{ followCursor: true, arrow: false, placement: 'top' }"
                                class="
                                    w-full
                                    sm:w-9/12
                                    xl:w-auto
                                    flex-1 flex
                                    justify-around
                                    items-center
                                    cursor-default
                                "
                            >
                                <div class="sm-title xl:md-title text-gray1 w-6/12 truncate">{{ item.name }}</div>
                                <div
                                    :content="'Nº artículos devueltos'"
                                    v-tippy="{ followCursor: true, arrow: false, placement: 'bottom' }"
                                    class="text-white text-sm xl:text-base"
                                >
                                    {{ item.value | numFormat('0,0a') }}
                                </div>
                                <div
                                    :content="'% devoluciones /ventas'"
                                    v-tippy="{ followCursor: true, arrow: false, placement: 'bottom' }"
                                    class="text-sm xl:text-base"
                                    :class="{ 'text-bad': item.percent > 10, 'text-white': item.percent <= 10 }"
                                >
                                    {{ item.percent | numFormat('0.0a') }}%
                                </div>
                            </div>
                        </template>
                    </ListLarge>
                </div>
            </div>
            <div class="w-full flex flex-col mt-3">
                <div
                    v-for="(returns, index) in data.returns_by_type"
                    :key="returns.name"
                    class="w-full flex flex-col extra:flex-row"
                >
                    <div class="w-full extra:w-3/12 h-32">
                        <Pie
                            v-if="returns.name == 'Motivos'"
                            :pieOptions="pieOptions"
                            :data="reasons_chart"
                            :chartId="pieOptions.id"
                        >
                            <i class="mdi mdi-package-variant-closed text-xl text-gray1"></i>
                            <span class="sm-title">{{ returns.name }}</span>
                        </Pie>
                        <Pie v-else :pieOptions="pieOptions" :data="supplier_chart" :chartId="pieOptions.id">
                            <i class="mdi mdi-package-variant-closed text-xl text-gray1"></i>
                            <span class="sm-title">{{ returns.name }}</span>
                        </Pie>
                    </div>

                    <div class="w-full extra:w-9/12 grid grid-cols-1 md:grid-cols-3 gap-1">
                        <Box v-for="(item, index) in type_returns[index].slice(0, 3)" :key="item.id">
                            <template v-slot:header>
                                <div class="w-full h-auto extra:h-10 overflow-auto">
                                    <i
                                        class="mdi mdi-package-variant-closed text-xl text-gray1"
                                        :style="{ color: pieOptions.colors[index] }"
                                    ></i>
                                    {{ item.name }}
                                </div>
                            </template>
                            <template v-slot:content>
                                <div class="flex flex-col justify-end mt-3">
                                    <Kpi :largeKpi="true">
                                        <template slot="kpi">% devoluciones</template>
                                        <template slot="value">{{ item.percent | numFormat('0.00') }} %</template>
                                    </Kpi>
                                    <Kpi :largeKpi="true">
                                        <template slot="kpi">nº total devoluciones</template>
                                        <template slot="value">{{ item.value | numFormat('0,0') }} </template>
                                    </Kpi>
                                </div>
                            </template>
                        </Box>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { SalesReturns } from '@/api/utils.js';
    import ModuleHeader from '@/components/header/ModuleHeader.vue';
    import Box from '@/components/Box.vue';
    import ButtonTabs from '@/components/elements/ButtonTabs.vue';
    import Kpi from '@/components/Kpi.vue';
    import ListLarge from '@/components/ListLarge.vue';
    import Pie from '@/components/charts/Pie.vue';
    import Gauge from '@/components/charts/Gauge.vue';

    export default {
        name: 'SalesReturns',
        components: {
            ModuleHeader,
            Box,
            ButtonTabs,
            Kpi,
            ListLarge,
            Pie,
            Gauge,
        },
        props: {
            data: {
                type: [Array, Object],
                required: true,
                default: () => [],
            },
            period: {
                type: Object,
                required: false,
                default: {},
            },
            market: {
                type: String,
                required: false,
                default: 'Mercado',
            },
        },
        data() {
            return {
                // data: {},
                selectedItemType: 0,
                pieOptions: {
                    id: 'pie',
                    colors: ['#FFA8A8', '#FF5D61', '#D35935'],
                    data: [33, 10, 4],
                    classname: 'z-30 relative overflow-visible',
                    innerSize: 75,
                    bgColor: '#202837',
                },
                gaugeOptions: {
                    id: 'gauge',
                    y: 24,
                    classname: 'z-30 relative',
                    innerSize: 85,
                    bgColor: '#2E384A',
                    paneColor: '#FF5D61',
                },
                tabs: [
                    {
                        id: 0,
                        type: 'products',
                        tab: 'Productos',
                    },
                    {
                        id: 1,
                        type: 'categories',
                        tab: 'Categorías',
                    },
                ],
            };
        },
        computed: {
            returns() {
                let list = [];
                this.tabs.map(type => {
                    list.push(type);
                });
                return list;
            },
            type_returns() {
                let types = [];
                this.data.returns_by_type.forEach(type => {
                    types.push(type.types);
                });
                return types;
            },
            reasons_chart() {
                // let reasons = [];
                // let supplier = [];
                // this.type_returns[0].forEach((item) => {
                //     reasons.push(item.value)
                // })
                // this.type_returns[1].forEach((item) => {
                //     supplier.push(item.value)
                // })
                // return [reasons,supplier];
                let pie = [];
                this.data.returns_by_type[0].types.map(item => {
                    pie.push({
                        id: item.id,
                        name: item.id,
                        y: item.value,
                        value: item.value,
                    });
                });
                return pie;
            },
            supplier_chart() {
                let pie = [];
                this.data.returns_by_type[1].types.map(item => {
                    pie.push({
                        id: item.id,
                        name: item.name,
                        y: item.value,
                        value: item.value,
                    });
                });
                return pie;
            },
        },
        methods: {
            selectedTab(tab) {
                this.selectedItemType = tab;
            },
        },
        created() {
            // this.data = SalesReturns;
        },
    };
</script>
