<template>
    <div class="flex flex-col">
        <div class="w-full bg-box relative rounded-sm shadow-inner h-4 z-10">
            <div
                :content="data.name + ': ' + data.percent.toFixed(1) + '<small>%</small>'"
                v-tippy="{ followCursor: true, arrow: false }"
                class="absolute left-0 top-0 bottom-0 rounded-sm shadow-lg h-4 z-20 transition"
                :style="{ width : data.percent + '%'}"
                :class="colorBg"></div>
        </div>
        <div class="w-full flex justify-between items-center">
            <div class="md-title truncate">{{ data.name }}</div>
            <div
                :content="datatype"
                v-tippy="{ followCursor: true, arrow: false }"
                class="md-value cursor-pointer">{{ data.value | numFormat("0,0")}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HorizontalBarChart',
    props: ['data','colorBg','datatype'],
}
</script>

<style>

</style>