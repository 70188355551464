var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full h-auto px-3 py-3"},[_c('Header'),[_c('div',{staticClass:"\n                grid grid-cols-1\n                extra:grid-cols-12\n                grid-row-12\n                gap-3\n                w-full\n                h-full\n                flex-1\n                grid-flow-row grid-rows-2\n                lg:grid-rows-3\n            "},[_c('div',{staticClass:"module col-span-full extra:col-span-7 z-50 row-span-2 globalSales"},[_c('ModuleTab'),_c('All',{key:"globl-sales",attrs:{"action":"Request","model":"ApiRequest","immediate":true,"sensitive":true,"query":{
                        from: _vm.period.from,
                        to: _vm.period.to,
                        country: _vm.marketId,
                    },"data":_vm.sales,"config":{ url: 'global-sales' }},on:{"update:data":function($event){_vm.sales=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var loadingGlobalSales = ref.loading;
return [(loadingGlobalSales)?_c('Loading'):_c('SalesGlobal',{key:"sales-global",attrs:{"data":_vm.sales.data,"period":_vm.period,"market":_vm.market.name},on:{"openModal":_vm.openModal}})]}}])})],1),(_vm.modal)?_c('div',{staticClass:"module col-span-full extra:col-span-5 row-span-2 h-full flex flex-col"},[_c('div',{staticClass:"w-full flex justify-between items-center"},[_c('div',{staticClass:"w-full flex justify-between relative mb-4"},[_c('div',{staticClass:"\n                                w-1/12\n                                cursor-pointer\n                                flex\n                                items-center\n                                text-gray1\n                                hover:text-casika\n                                transition\n                                duration-500\n                                ease-in-out\n                            ",on:{"click":_vm.closeModal}},[_c('i',{staticClass:"mdi mdi-close mr-2 text-2xl rotate-0 hover:rotate-45 transition"}),_vm._v(" Cerrar ")])]),_c('ButtonTabs',{staticClass:"w-1/2",attrs:{"createdTabs":_vm.tabs},on:{"selectedTab":_vm.selectedTab}})],1),_c('All',{key:"sales-admin",attrs:{"action":"Request","model":"ApiRequest","immediate":true,"sensitive":true,"query":_vm.queryAdmin,"data":_vm.admin_sales,"config":{ url: 'sales-admin' }},on:{"update:data":function($event){_vm.admin_sales=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var loadingAdmin = ref.loading;
return [(loadingAdmin)?_c('Loading'):_c('SalesAdmin',{key:"sales-admin",attrs:{"data":_vm.admin_sales.data},on:{"selectedPeriod":_vm.selectedPeriod,"closeModal":_vm.closeModal}})]}}],null,false,1143712321)})],1):_vm._e(),_c('All',{key:"sales-returns",attrs:{"action":"Request","model":"ApiRequest","immediate":true,"sensitive":true,"query":{
                    from: _vm.period.from,
                    to: _vm.period.to,
                    country: _vm.marketId,
                },"data":_vm.returns,"config":{ url: 'returns' }},on:{"update:data":function($event){_vm.returns=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var loadingReturns = ref.loading;
return [(!_vm.modal)?_c('div',{staticClass:"module col-span-full extra:col-span-5 row-span-2"},[_c('ModuleTab'),(loadingReturns)?_c('Loading'):_c('SalesReturns',{key:"returns",attrs:{"data":_vm.returns.data,"period":_vm.period,"market":_vm.market.name}})],1):_vm._e()]}}])}),_c('div',{staticClass:"module col-span-full extra:col-span-7 row-span-1"},[_c('ModuleTab'),_c('All',{key:"top-20",attrs:{"action":"Request","model":"ApiRequest","immediate":true,"sensitive":true,"query":{
                        from: _vm.period.from,
                        to: _vm.period.to,
                        country: _vm.marketId,
                        limit: 20,
                    },"data":_vm.top_20,"config":{ url: 'top-20' }},on:{"update:data":function($event){_vm.top_20=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var loadingTop20 = ref.loading;
return [(loadingTop20)?_c('Loading'):_c('SalesTop20',{key:"sales-top",attrs:{"data":_vm.top_20.data,"period":_vm.period,"market":_vm.market.name}})]}}])})],1),_c('div',{staticClass:"module col-span-full extra:col-span-3 row-span-1"},[_c('ModuleTab'),_c('All',{key:"pay-methods",attrs:{"action":"Request","model":"ApiRequest","immediate":true,"sensitive":true,"query":{
                        from: _vm.period.from,
                        to: _vm.period.to,
                        country: _vm.marketId,
                    },"data":_vm.payments,"config":{ url: 'pay-methods' }},on:{"update:data":function($event){_vm.payments=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var loadingPayments = ref.loading;
return [(loadingPayments)?_c('Loading'):_c('SalesPayment',{key:"pay-methods",attrs:{"data":_vm.payments.data,"period":_vm.period,"market":_vm.market.name}})]}}])})],1),_c('div',{staticClass:"module col-span-full extra:col-span-2 row-span-1"},[_c('ModuleTab'),_c('All',{key:"sources",attrs:{"action":"Request","model":"ApiRequest","immediate":true,"sensitive":true,"query":{
                        from: _vm.period.from,
                        to: _vm.period.to,
                        country: _vm.marketId,
                    },"data":_vm.sources,"config":{ url: 'sources' }},on:{"update:data":function($event){_vm.sources=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var loadingSources = ref.loading;
return [(loadingSources)?_c('Loading'):_c('SalesSource',{attrs:{"data":_vm.sources.data,"period":_vm.period,"market":_vm.market.name}})]}}])})],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }