<template>
  <div class="relative chart-wrap">
    <div
      class="absolute top-0 left-0 h-full w-full flex flex-col items-center justify-center text-xs z-0"
    >
      <slot />
    </div>
    <highcharts
      :options="options"
      :chartId="chartId"
      class="z-50"
    ></highcharts>
    
  </div>
</template>

<script>
export default {
  name: 'GaugeChart2',
  props: [
    'chartId',
    'gaugeOptions',
    'gradientGauge',
    'colorSuccessPane',
    'colorWarningPane',
    'primary',
    'secondary',
    'primary_name',
    'secondary_name'
  ],
  computed: {

    options() {
      return {
        chart: {
          type: "solidgauge",
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          borderWidth: 0,
          backgroundColor: "rgba(255,255,255,0)",
          margin: [0, 0, 0, 0],
          spacingTop: 0,
          spacingBottom: 0,
          spacingRight: 0,
          spacingLeft: 0,
        },
        title: {
          text: "",
          align: "center",
          verticalAlign: "middle",
          y: 40,
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        pane: {
            startAngle: 0,
            endAngle: 360,
            background: {
                outerRadius: '100%',
                innerRadius: this.gaugeOptions.innerSize + '%',
                backgroundColor: this.gaugeOptions.bgColor,
                borderWidth: 0,
                shape: "arc",
            },
        },
        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: [],
        },
        tooltip: {
            enabled: true,
            style: {
                color: "#fff",
                fontSize: '.7rem'
            },
            shared: true,
            useHTML: true,
            //outside: true,
            borderRadius: 20,
            borderWidth: 0,
            pointFormat:
              '<span style="color: rgb(121, 132, 154);">{series.name}:</span> <span style="font-size:.95rem;"><b>{point.y:,.1f}<span style="font-size:.75rem;">%</span></b></span><br/>',
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              enabled: false,
            },
            linecap: 'squared',
            stickyTracking: false,
          },
          series: {
            animation: {
              duration: 4000,
            },
          },
        },
        series: [
          {
            name: this.primary_name,
            data: [
              {
                radius: '100%',
                innerRadius: this.gaugeOptions.innerSize + '%',
                y: this.primary,
                color: this.primary < this.secondary ? this.colorWarningPane : this.colorSuccessPane,
              },
            ],
            rounded: false,
          },{
            name: this.secondary_name,
            data: [
              {
                radius: '75%',
                innerRadius: '70%',
                y: this.secondary,
                color: '#596575'
              },
            ],
            rounded: true,
          }
        ],
      };
    },

  },
};
</script>