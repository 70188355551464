<template>
    <div class="w-full flex flex-col">
        <ModuleHeader>
            <template v-slot:module>
                Top 20
            </template>
            <template v-slot:period>
                {{ period.from | moment("DD MMM YY") }} <i class="mdi mdi-arrow-right"></i> {{ period.to | moment("DD MMM YY") }}
            </template>
            <template v-slot:market>
                {{ market }}
            </template>
        </ModuleHeader>

        <div class="grid grid-cols-2 sm:grid-cols-5 tb:grid-cols-7 lg:grid-cols-10 gap-1">

            <!-- Get max 20 items -->

            <ProductCard
                v-for="( item,index ) in showData.slice(0, 20)"
                :key="'card' + item.id"
                :data="item" />
        </div>

    </div>
</template>

<script>
    import ModuleHeader from '@/components/header/ModuleHeader.vue';
    import ProductCard from '@/components/ProductCard.vue';
    export default {
        name: 'SalesTop20',
        props: {
            data: {
                type: Array,
                required: true,
                default: () => ([])
            },
            period: {
                type: Object,
                required: false,
                default: {}
            },
            market: {
                type: String,
                required: false,
                default: 'Mercado'
            }
        },
        computed: {
          showData() {
              return this.data
          },
        },
        components: {
            ModuleHeader,
            ProductCard,
        },
    }
</script>