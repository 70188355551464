<template>
    <div class="relative chart-wrap">
        <highcharts class="w-full h-full chart" :options="options" :ref="chartId" :id="chartId"></highcharts>
    </div>
</template>

<script>
export default {
    name: 'StackedPercentChart',
    props: ['chartId','data'],
    computed: {
        // series() {
        //     return this.data.map((item)=>{
        //         return item;
        //     })
        // },
        options() {
            return {
                chart: {
                    type: 'column',
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    backgroundColor:'rgba(255,255,255,0)',
                    spacingBottom: 10,
                    spacingTop: 100,
                    spacingRight: 0,
                    spacingLeft: 0,
                    marginTop: 10,
                    marginRight: 0,
                    marginRLeft: 0,
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                credits:{
                    enabled: false
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        month: '%b'
                    },
                    lineColor: 'rgba(255,255,255,0)',
                    title: {
                        text: null
                    },
                    labels: {
                        style:{
                            color:'#79849A',
                            fontWeight: 'medium',
                            fontSize: '7.5px',
                        }
                    },
                    tickPixelInterval: 75,
                    tickWidth: 0,
                },{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        month: '%b'
                    },
                    lineColor: 'rgba(255,255,255,0)',
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: false,
                    },
                    tickPixelInterval: 75,
                    tickWidth: 0,
                    opposite: true
                }],
                // time: {
                //     timezoneOffset: new Date().getTimezoneOffset()
                // },
                yAxis: {
                    title: {
                        text: '',
                    },
                    lineColor: '#79849A',
                    lineWidth: 1,
                    gridLineColor: 'transparent',
                    labels: {
                        enabled: true,
                        style:{
                            color: '#79849A',
                            fontWeight: 'medium',
                            fontSize: '7.5px',
                        },
                        format:  '{value:,.0f}',
                    },
                    min: 0,
                    //max: 100,
                },
                tooltip: {
                    enabled:true,
                    style: {
                        color: "#fff",
                    },
                    shared: true,
                    borderRadius: 20,
                    borderWidth: 0,
                    headerFormat: '<span style="font-size:.65rem; color: rgb(121, 132, 154);">{point.key}</span><br>',
                    xDateFormat: "%B %Y",
                    pointFormat:
                    '<span class="capitalize" style="color:{point.color}">{series.name}</span>: <b>{point.y:,.0f}</b><br/>',
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        borderRadius: 1,
                        borderWidth: 1,
                        borderColor: '#202837',
                        dataLabels: {
                            enabled: false
                        }
                    },
                    series: {
                        shadow: true,
                        pointWidth: 7,
                    }
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                data: {
                    enablePolling: true,
                    dataRefreshRate: 1
                },
                series: [{
                    name: '1 / 2 ★',
                    data: this.data.bad,
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, '#FF5D61'],
                            [1, '#FF5D61']
                        ]
                    },
                    stack: 'One',
                },{
                    name: '3 ★',
                    data: this.data.regular,
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, '#F7C159'],
                            [1, '#F7C159']
                        ]
                    },
                    stack: 'One',
                },{
                    name: '4 / 5 ★',
                    data: this.data.good,
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, '#6ECAA4'],
                            [1, '#6ECAA4']
                        ]
                    },
                    stack: 'One',
                },
                // {
                //     name: 'Bad Previous',
                //     data: [20,30,40,20,30,40,20,30,40,20,30,40],
                //     color: {
                //         linearGradient: {
                //             x1: 0,
                //             x2: 0,
                //             y1: 0,
                //             y2: 1
                //         },
                //         stops: [
                //             [0, '#FF5D6155'],
                //             [1, '#FF5D6155']
                //         ]
                //     },
                //     stack: 'Two',
                // },{
                //     name: 'Regular previous',
                //     data: [20,30,40,20,30,40,20,30,40,20,30,40],
                //     color: {
                //         linearGradient: {
                //             x1: 0,
                //             x2: 0,
                //             y1: 0,
                //             y2: 1
                //         },
                //         stops: [
                //             [0, '#F7C15955'],
                //             [1, '#F7C15955']
                //         ]
                //     },
                //     stack: 'Two',
                // },{
                //     name: 'Good previous',
                //     data: [20,30,40,20,30,40,20,30,40,20,30,40],
                //     color: {
                //         linearGradient: {
                //             x1: 0,
                //             x2: 0,
                //             y1: 0,
                //             y2: 1
                //         },
                //         stops: [
                //             [0, '#6ECAA455'],
                //             [1, '#6ECAA455']
                //         ]
                //     },
                //     stack: 'Two',
                // }
                ]
            }
        }
    },
    data() {
        return {
        }
    },

    methods: {

    },
    watch: {

    },
    mounted() {
    }
};
</script>
