<template>
    <div class="w-full flex flex-col">
        <ModuleHeader>
            <template v-slot:module> Fuentes </template>
            <template v-slot:period>
                {{ period.from | moment('DD MMM YY') }} <i class="mdi mdi-arrow-right"></i>
                {{ period.to | moment('DD MMM YY') }}
            </template>
            <template v-slot:market>
                {{ market }}
            </template>
        </ModuleHeader>

        <div class="w-full flex flex-col overflow-auto" style="max-height: 240px">
            <HorizontalBar
                v-for="(item, index) in data"
                :key="item.id"
                :data="item"
                :datatypes="'Páginas vistas'"
                :colorBg="'orange-gradient'"
            />
        </div>
    </div>
</template>

<script>
    import { SalesSource } from '@/api/utils.js';
    import ModuleHeader from '@/components/header/ModuleHeader.vue';
    import HorizontalBar from '@/components/charts/HorizontalBar.vue';
    export default {
        name: 'SalesSource',
        props: {
            data: {
                type: Array,
                required: true,
                default: () => [],
            },
            period: {
                type: Object,
                required: false,
                default: {},
            },
            market: {
                type: String,
                required: false,
                default: 'Mercado',
            },
        },
        components: {
            ModuleHeader,
            HorizontalBar,
        },
        data() {
            return {
                // data: [],
            };
        },
        created() {
            //this.data = SalesSource;
        },
    };
</script>
