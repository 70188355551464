<template>
    <div class="w-auto flex flex-col">
        <div
            
            class="rounded-md overflow-hidden mb-2 relative bg-white">

            <img v-if="data.image" 
                :content="data.name"
                v-tippy="{ followCursor: true, arrow: false }"
                :src="data.image" alt="" />
            <img v-else
                scontent="data.name"
                v-tippy="{ followCursor: true, arrow: false }"
                src="img/default.jpg" alt="" />

            <div class="absolute bottom-0 left-0 mb-1 w-full flex justify-between items-center px-1">

                <Type 
                    :type="data.type" />
                    
                <Chip 
                    :colorBg="'bg-gray-900'" 
                    :colorText="'text-catBlue'">
                    {{ data.value | numFormat('0,0.0a')}} €
                </Chip>
            </div>
        </div>
        <div class="xs-title truncate">{{ data.name }}</div>
    </div>
</template>

<script>
    import Chip from '@/components/Chip.vue';
    import Type from '@/components/Type.vue';
    export default {
        name: 'ProductCardSmall',
        components: {
            Chip,
            Type,
        },
        props: {
            data: {
                type: Object,
                required: false,
                default: () => ({})
            },
        }
    }
</script>

<style>

</style>