<template>
    <div class="w-full h-auto px-3 py-3">
        <Header />


        <template>
            <div
                class="
                    grid grid-cols-1
                    extra:grid-cols-12
                    grid-row-12
                    gap-3
                    w-full
                    h-full
                    flex-1
                    grid-flow-row grid-rows-2
                    lg:grid-rows-3
                "
            >
                <div class="module col-span-full extra:col-span-7 z-50 row-span-2 globalSales">
                    <ModuleTab />
                    <All
                        action="Request"
                        model="ApiRequest"
                        key="globl-sales"
                        :immediate="true"
                        :sensitive="true"
                        :query="{
                            from: period.from,
                            to: period.to,
                            country: marketId,
                        }"
                        :data.sync="sales"
                        v-slot="{ loading: loadingGlobalSales }"
                        :config="{ url: 'global-sales' }"
                    >
                        <Loading v-if="loadingGlobalSales" />
                        <SalesGlobal 
                            v-else 
                            key="sales-global" 
                            :data="sales.data" 
                            :period="period"
                            :market="market.name"
                            @openModal="openModal" 
                        />
                    </All>
                </div>

                <div v-if="modal" class="module col-span-full extra:col-span-5 row-span-2 h-full flex flex-col">
                    <div class="w-full flex justify-between items-center">
                        <div class="w-full flex justify-between relative mb-4">
                            <div
                                class="
                                    w-1/12
                                    cursor-pointer
                                    flex
                                    items-center
                                    text-gray1
                                    hover:text-casika
                                    transition
                                    duration-500
                                    ease-in-out
                                "
                                @click="closeModal"
                            >
                                <i class="mdi mdi-close mr-2 text-2xl rotate-0 hover:rotate-45 transition"></i> Cerrar
                            </div>
                        </div>
                        <ButtonTabs class="w-1/2" :createdTabs="tabs" @selectedTab="selectedTab" />
                    </div>
                    <All
                        action="Request"
                        model="ApiRequest"
                        key="sales-admin"
                        :immediate="true"
                        :sensitive="true"
                        :query="queryAdmin"
                        :data.sync="admin_sales"
                        v-slot="{ loading: loadingAdmin }"
                        :config="{ url: 'sales-admin' }"
                    >
                        <Loading v-if="loadingAdmin"></Loading>
                        <SalesAdmin
                            v-else
                            key="sales-admin"
                            :data="admin_sales.data"
                            @selectedPeriod="selectedPeriod"
                            @closeModal="closeModal"
                        />
                    </All>
                </div>

                <All
                    action="Request"
                    model="ApiRequest"
                    key="sales-returns"
                    :immediate="true"
                    :sensitive="true"
                    :query="{
                        from: period.from,
                        to: period.to,
                        country: marketId,
                    }"
                    :data.sync="returns"
                    v-slot="{ loading: loadingReturns }"
                    :config="{ url: 'returns' }"
                >
                    <div v-if="!modal" class="module col-span-full extra:col-span-5 row-span-2">
                        <ModuleTab />

                        <Loading v-if="loadingReturns" />
                        <SalesReturns
                            v-else
                            key="returns"
                            :data="returns.data"
                            :period="period"
                            :market="market.name"
                        />
                    </div>
                </All>

                <div class="module col-span-full extra:col-span-7 row-span-1">
                    <ModuleTab />
                    <All
                        action="Request"
                        model="ApiRequest"
                        key="top-20"
                        :immediate="true"
                        :sensitive="true"
                        :query="{
                            from: period.from,
                            to: period.to,
                            country: marketId,
                            limit: 20,
                        }"
                        :data.sync="top_20"
                        v-slot="{ loading: loadingTop20 }"
                        :config="{ url: 'top-20' }"
                    >
                        <Loading v-if="loadingTop20" />
                        <SalesTop20 v-else key="sales-top" :data="top_20.data" :period="period" :market="market.name" />
                    </All>
                </div>

                <div class="module col-span-full extra:col-span-3 row-span-1">
                    <ModuleTab />
                    <All
                        action="Request"
                        model="ApiRequest"
                        key="pay-methods"
                        :immediate="true"
                        :sensitive="true"
                        :query="{
                            from: period.from,
                            to: period.to,
                            country: marketId,
                        }"
                        :data.sync="payments"
                        v-slot="{ loading: loadingPayments }"
                        :config="{ url: 'pay-methods' }"
                    >
                        <Loading v-if="loadingPayments" />
                        <SalesPayment
                            v-else
                            key="pay-methods"
                            :data="payments.data"
                            :period="period"
                            :market="market.name"
                        />
                    </All>
                </div>

                <div class="module col-span-full extra:col-span-2 row-span-1">
                    <ModuleTab />
                    <All
                        action="Request"
                        model="ApiRequest"
                        key="sources"
                        :immediate="true"
                        :sensitive="true"
                        :query="{
                            from: period.from,
                            to: period.to,
                            country: marketId,
                        }"
                        :data.sync="sources"
                        v-slot="{ loading: loadingSources }"
                        :config="{ url: 'sources' }"
                    >
                        <Loading v-if="loadingSources" />
                        <SalesSource v-else :data="sources.data" :period="period" :market="market.name" />
                    </All>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { All } from '@/api/components';
    import { actions, state } from '@/store';
    import Loading from '@/components/elements/Loading';
    import Header from '@/components/header/Header';
    import ButtonTabs from '@/components/elements/ButtonTabs.vue';
    import ModuleTab from '@/components/elements/ModuleTab';
    import SalesGlobal from '@/components/module/SalesGlobal';
    import SalesReturns from '@/components/module/SalesReturns';
    import SalesAdmin from '@/components/module/SalesAdmin';
    import SalesTop20 from '@/components/module/SalesTop20';
    import SalesPayment from '@/components/module/SalesPayment';
    import SalesSource from '@/components/module/SalesSource';

    export default {
        name: 'SalesPage',
        components: {
            All,
            Header,
            ButtonTabs,
            Loading,
            ModuleTab,
            SalesGlobal,
            SalesReturns,
            SalesAdmin,
            SalesTop20,
            SalesPayment,
            SalesSource,
        },
        data() {
            return {
                sales: [],
                returns: [],
                payments: [],
                sources: [],
                top_20: [],
                admin_sales: [],
                modal: false,
                admin_period: 'yesterday',
                tabs: [
                    {
                        id: 0,
                        tab: 'Ayer',
                        period: 'yesterday',
                    },
                    {
                        id: 1,
                        tab: 'Semana',
                        period: 'week',
                    },
                    {
                        id: 2,
                        tab: 'Mes',
                        period: 'month',
                    },
                    {
                        id: 3,
                        tab: 'Mes Anterior',
                        period: 'last_month',
                    },
                ],
            };
        },
        computed: {
            user() {
                return state.user;
            },
            market() {
                return state.market;
            },
            marketId() {
                return state.market.id;
            },
            period() {
                return {
                    from: this.$moment(state.period.from).format('YYYY-MM-DD'),
                    to: this.$moment(state.period.to).format('YYYY-MM-DD'),
                };
            },
            yesterday() {
                return {
                    from: this.$moment(state.yesterday.from).format('YYYY-MM-DD'),
                    to: this.$moment(state.yesterday.to).subtract(1, 'day').endOf('day').format('YYYY-MM-DD'),
                };
            },
            week() {
                return {
                    from: this.$moment(state.week.from).format('YYYY-MM-DD'),
                    to: this.$moment(state.week.to).format('YYYY-MM-DD'),
                };
            },
            last_month() {
                return {
                    from: this.$moment(state.last_month.from).format('YYYY-MM-DD'),
                    to: this.$moment(state.last_month.to).format('YYYY-MM-DD'),
                };
            },
            month() {
                return {
                    from: this.$moment(state.month.from).format('YYYY-MM-DD'),
                    to: this.$moment(state.month.to).format('YYYY-MM-DD'),
                };
            },
            queryAdmin() {
                return {
                    ...this[this.admin_period],
                    country: this.marketId,
                    period: this.admin_period
                };
            },
        },
        watch: {
            // queryAdmin(n,o) {
            //      console.log('queryAdmin en Sales :>> ', n);
            // }
        },
        methods: {
            openModal() {
                this.modal = !this.modal;
            },
            closeModal() {
                this.admin_period = 'yesterday';
                this.modal = false;
            },
            selectedTab(tab) {
                this.admin_period = this.tabs[tab].period;
                console.log('this.tabs[tab].period :>> ', this.tabs[tab].period);
                //this.$emit('selectedPeriod', this.tabs[tab])
            },
            selectedPeriod(period) {
                // this.admin_period = period.period;
                this.admin_period = this.tabs[tab].period;
                console.log('period.periodd :>> ', period.period);
            },
        },
        beforeCreate() {
            actions.setPeriod({
                from: this.$moment().startOf('month').locale('es').toDate(),
                to: this.$moment().endOf('month').subtract(1, "days").toDate(),
            });
        }
    };
</script>

<style>
    @media (min-width: 768px) {
        .globalSales {
            min-height: 60vh;
        }
    }
</style>
