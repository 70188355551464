<template>
    <div class="flex items-center ml-4">
        <i
            class="mdi mdi-chart-timeline-variant mr-2 text-lg"            
            :style="{ color: colorIcon}"></i>
        <span
            class="text-xs text-gray2">
            <slot name="legend"></slot>
        </span>
        
    </div>
</template>

<script>
    export default {
        name: 'Legend',
        props: ['colorIcon'],
    }
</script>