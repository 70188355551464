<template>
    <div class="inline-flex justify-center items-center rounded-full w-5 h-5 p-1" :class="productCategory(type)">
        <span
            :content="type"
            v-tippy="{ followCursor: true, arrow: false }"
            class="text-sm text-gray-800" >
            <!-- <i class="mdi text-md"
                :class="'mdi-' + type"></i> -->
            <span
                :class="'icon-' + type" class="text-sm"></span>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'TypeData',
        props: ['colorBg','colorText','type'],
        methods: {
            productCategory(category) {
                return {
                    'bg-catBlue' : category == 'hipertop',
                    'bg-catPurple' : category == 'top',
                    'bg-catPink' : category == 'market',
                    'bg-catOrange' : category == 'market',
                }
            }
        }
    }
</script>

<style>

</style>