<template>
    <div class="w-full flex flex-col h-full">
        <div id="anchor" class="block extra:hidden"></div>

        <template>
            <div class="w-full h-full flex flex-col justify-between">
                <div class="grid grid-cols-12 gap-3">
                    <Box class="col-span-12 extra:col-span-8">
                        <template v-slot:header> Ventas (sin IVA) </template>
                        <template v-slot:content>
                            <div class="flex flex-col items-center py-2">
                                <span class="text-white text-lg xl:text-xl font-medium"
                                    >{{ data.sales_value | numFormat('0,0.0a') }} €</span
                                >
                                <span class="text-sm font-medium text-gray1"
                                    >{{ data.sales_percent | numFormat('0.0a') }}%</span
                                >
                            </div>
                        </template>
                    </Box>
                    <Box class="col-span-12 extra:col-span-4 border-2 border-yellow-500">
                        <template v-slot:header> Descuentos </template>
                        <template v-slot:content>
                            <div class="flex flex-col items-center py-2">
                                <span class="text-white text-lg xl:text-xl font-medium"
                                    >{{ data.discounts_value | numFormat('0,0.0a') }} €</span
                                >
                                <span class="text-sm font-medium text-gray1"
                                    >{{ data.discounts_percent | numFormat('0.0a') }}%</span
                                >
                            </div>
                        </template>
                    </Box>
                </div>

                <span class="flex justify-center md:hidden text-catPurple text-lg font-medium mt-2">Total ventas</span>

                <div class="grid grid-cols-12 gap-3 flex-1 h-auto">
                    <div class="col-span-12 extra:col-span-8 f-center py-1">
                        <div
                            :content="'Total ingresos'"
                            v-tippy="{ followCursor: true, arrow: false }"
                            class="
                                flex
                                justify-center
                                items-center
                                border-2 border-catPurple
                                rounded-full
                                px-3
                                cursor-pointer
                            "
                        >
                            <i class="mdi mdi-cash-check text-gray1 text-2xl mr-3"></i>
                            <div class="md-value font-medium">{{ data.total_sales | numFormat('0,0.0a') }} €</div>
                        </div>
                    </div>
                </div>

                <div class="grid grid-cols-12 gap-3">
                    <div class="col-span-12 extra:col-span-8 grid grid-cols-12 gap-3">
                        <Box v-for="item in data.expenses" :key="item.id" class="col-span-12 extra:col-span-4">
                            <template v-slot:header>
                                <div
                                    :content="item.type"
                                    v-tippy="{ followCursor: true, arrow: false }"
                                    class="w-full extra:w-11/12 truncate cursor-default"
                                >
                                    {{ item.type }}
                                </div>
                            </template>
                            <template v-slot:content>
                                <div class="flex flex-col items-center py-2">
                                    <span class="text-white text-lg xl:text-xl font-medium">
                                        {{ item.value | numFormat('0,0.0a') }} €
                                    </span>
                                    <span class="text-sm font-medium text-gray1">
                                        {{ item.percent | numFormat('0.0a') }} %
                                    </span>
                                </div>
                            </template>
                        </Box>
                    </div>
                    <div class="col-span-12 extra:col-span-4 grid grid-cols-12 gap-3">
                        <Box
                            v-for="item in data.expenses_social"
                            :key="item.id"
                            class="col-span-12 extra:col-span-6 border-2 border-bad"
                        >
                            <template v-slot:header>
                                <div class="f-center">
                                    <img class="w-6" :src="'img/icons/' + item.id + '.svg'" alt="" />
                                </div>
                            </template>
                            <template v-slot:content>
                                <div class="flex flex-col items-center py-2">
                                    <span class="text-white text-sm xl:text-xl font-medium">
                                        {{ item.value | numFormat('0,0.0a') }} €
                                    </span>
                                    <span class="text-sm font-medium text-gray1">
                                        {{ item.percent | numFormat('0.0a') }}%
                                    </span>
                                </div>
                            </template>
                        </Box>
                    </div>
                </div>

                <span class="flex justify-center md:hidden text-bad text-lg font-medium mt-2">Total gastos</span>

                <div class="grid grid-cols-12 gap-3 flex-1 h-auto">
                    <div class="col-span-12 extra:col-span-12 f-center py-1">
                        <div
                            :content="'Total gastos'"
                            v-tippy="{ followCursor: true, arrow: false }"
                            class="
                                mr-3
                                flex
                                justify-center
                                items-center
                                border-2 border-bad
                                rounded-full
                                px-3
                                cursor-pointer
                            "
                        >
                            <i class="mdi mdi-cash-multiple text-gray1 text-2xl mr-3"></i>
                            <div class="md-value font-medium">{{ data.total_expenses | numFormat('0,0.0a') }} €</div>
                        </div>
                        <Chip :colorBg="'bg-bad-opacity-33'" :colorText="'text-bad'">
                            <span v-if="data.expenses_growth > 0">+</span
                            >{{ data.expenses_growth | numFormat('0.0a') }} %
                        </Chip>
                    </div>
                </div>

                <div class="grid grid-cols-12 gap-3">
                    <Box
                        class="col-span-12 border-2"
                        :class="{
                            'border-bad bg-bad-opacity-20': data.profit_value < 0,
                            'border-good bg-good-opacity-20': data.profit_value >= 0,
                        }"
                    >
                        <template v-slot:header> Profit </template>
                        <template v-slot:content>
                            <div class="col-span-12 extra:col-span-12 f-center py-2">
                                <div class="mr-3 flex justify-center items-center">
                                    <div class="xl-value font-medium">
                                        {{ data.profit_value | numFormat('0,0.0a') }} €
                                    </div>
                                </div>
                                <Chip
                                    :colorBg="{
                                        'bg-bad-opacity-33': data.profit_value < 0,
                                        'bg-good-opacity-33': data.profit_value >= 0,
                                    }"
                                    :colorText="{
                                        'text-bad': data.profit_value < 0,
                                        'text-good': data.profit_value >= 0,
                                    }"
                                >
                                    <span v-if="data.profit_growth > 0">+</span
                                    >{{ data.profit_growth | numFormat('0.0a') }} %
                                </Chip>
                            </div>
                        </template>
                    </Box>
                </div>

                <div class="grid grid-cols-12 gap-3">
                    <div
                        v-for="support in data.supports"
                        :key="support.id"
                        class="
                            bg-box bg-box
                            rounded-lg
                            py-2
                            px-3
                            my-1
                            col-span-12
                            extra:col-span-3
                            flex
                            justify-between
                            items-center
                            text-gray1
                        "
                    >
                        <span class="text-xs">{{ support.type }}</span>

                        <span v-if="support.datatype === 'currency'" class="text-sm font-medium">
                            {{ support.value | numFormat('0,0.0a') }} €
                        </span>
                        <span v-else-if="support.datatype === 'percent'" class="text-sm font-medium"
                            >{{ support.value | numFormat('0.0a') }} %</span
                        >
                        <span v-else class="text-sm font-medium">{{ support.value | numFormat('0,0a') }}</span>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import Box from '@/components/Box.vue';
    import Kpi from '@/components/Kpi.vue';
    import Chip from '@/components/Chip.vue';
    export default {
        name: 'SalesAdmin',
        components: {
            Box,
            Kpi,
            Chip,
        },
        props: {
            data: {
                type: [Array, Object],
                required: false,
                default: () => ({}),
            },
        },
    };
</script>
